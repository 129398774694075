var lazyLoadInstance = new LazyLoad({
  elements_selector: "img"

});

$(document).ready(function() {
  //utm
  var buttons = document.getElementsByClassName('register_btn');
  for (var i = 0; i < buttons.length; i++) {
    buttons[i].href += window.location.search;
  }

  //smooth scrolling
  $("header nav a").on("click", function(event) {
    event.preventDefault();
    var id = $(this).attr('href'),
      top = $(id).offset().top;
    $('body,html').animate({
      scrollTop: top - 100
    }, 600);
  });

  cookiesUx(); //вызов ux, который происходит с куками
  //функция обработки куки
  function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  //функция установки куки
  function setCookie(name, value, options) {
    options = options || {};
    var expires = options.expires;
    if (typeof expires == "number" && expires) {
      var d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }
    value = encodeURIComponent(value);
    var updatedCookie = name + "=" + value;
    for (var propName in options) {
      updatedCookie += "; " + propName;
      var propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += "=" + propValue;
      }
    }
    document.cookie = updatedCookie;
  }

  function cookiesUx() { //ux, который происходит с куками
    var cookiesBlock = document.getElementById('js_cookies'); // блок, который скрываем
    if (getCookie('c_16102018')) { //проверяем, если есть кука. Конечно, если заходим первый раз - ее нет.
      cookiesBlock.hidden = true; // но если кука есть уже - блок скрываем. Почитай про hidden. Это по красоте.
      return; //Прерываем выполнение остального кода.
    }
    // Если куки нет и условие не прошло - идем дальше.
    var CookiesBlockOk = document.getElementById('js_cookies__ok'); // триггерим кнопку "ок"
    var options = { // создаем объект куки с датой, когда она протухнет. это год. бессмертных кук не бывает!!!
      expires: new Date(Date.now() + (1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 365))
    };
    CookiesBlockOk.addEventListener('click', function() { // по клику на ок - ставим куку с этими параметрами
      setCookie('c_16102018', 1, options);
      cookiesBlock.hidden = true; //скрываем блок с предупреждением
    });
  }



  //countdown 
  $('.timeout__date').countdown('2019/10/16 12:00:00', function(event) {
    var $this = $(this).html(event.strftime('' +
      '<div class="timeout__item timeout__item--days">%D<span>дней</span></div>' +
      '<div class="timeout__item timeout__item--hours">%H<span>часов</span></div>' +
      '<div class="timeout__item timeout__item--minutes">%M<span>минут</span></div>' +
      '<div class="timeout__item timeout__item--seconds">%S<span>секунд</span></div>'
    ));
  });

  //menu
  var html = $('html');
  //menu: show
  $('.menu--open').click(function() {
    html.addClass('menu-shown');
  });
  //menu: hide
  $('.menu--close, .overlay').click(function() {
    html.removeClass('menu-shown');
  });
  //menu: click item and hide
  $('nav a').click(function() {
    html.removeClass('menu-shown');
  });
  //reset on resize
  $(window).resize(function() {
    if ($(window).width() > 1024) {
      html.removeClass('menu-shown');
    }
  });
});